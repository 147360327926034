
  import { GForm } from '@/components/forms/GForm'
  import { Component, Watch } from 'vue-property-decorator'
  import TodayActivityList from '@/components/person/leadActivity/TodayActivityList.vue'
  import dayjs from 'dayjs'
  import { CalendarEvent } from 'vuetify'
  import GroupTaskDetail from '@/components/forms/fields/agenda/GroupTaskDetail.vue'
  import { mapActions } from 'vuex'

@Component({
  components: {
    TodayActivityList,
    GroupTaskDetail,
  },
  methods: {
    ...mapActions('dashboard', [
      'getDeliveryStatus',
    ]),
  },
  filters: {
    dateFilter: str => {
      return dayjs(str).format('DD/MM/YYYY - HH:mm')
    },
    hourFilter: str => {
      return dayjs(str).format('HH:mm')
    },
  },
})
  export default class DeliveryScheduleView extends GForm {
  categories = ['Día', 'Semana', 'Mes']
  type = ['day', 'week', 'month']
  category = 2;
  deliveries: Record<string, any> = []
  events: CalendarEvent[] = []
  utcOffset = dayjs().utcOffset()
  selectedEvent: CalendarEvent = {}
  displayEvent = false
  focus = ''
  currentMonth = ''
  queryDate = dayjs()
  timeStart = ''
  timeInterval = 14
  dayjs = dayjs

  // Methods
  getDeliveryStatus!: (variable) => Promise<Record<string, any>>

  prev () {
    this.$refs.calendar.prev()
    if (this.category === 0) {
      this.queryDate = this.queryDate.subtract(1, 'day')
    }
    if (this.category === 1) {
      this.queryDate = this.queryDate.subtract(1, 'week')
    }
    if (this.category === 2) {
      this.queryDate = this.queryDate.subtract(1, 'month')
    }
  }

  next () {
    this.$refs.calendar.next()
    if (this.category === 0) {
      this.queryDate = this.queryDate.add(1, 'day')
    }
    if (this.category === 1) {
      this.queryDate = this.queryDate.add(1, 'week')
    }
    if (this.category === 2) {
      this.queryDate = this.queryDate.add(1, 'month')
    }
  }

  async showEvent ({ nativeEvent, event }) {
    this.selectedEvent = event
    this.displayEvent = true
  }

  showDay ({ date }) {
    this.queryDate = dayjs(date)
    this.focus = date
    this.category = 0
  }

  closeEventCard () {
    this.displayEvent = false
  }

  async getData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'lead_activity' } },
    })
    const deliveries = await this.getDeliveryStatus({
      start_date: this.queryDate.startOf('month').subtract(7, 'day').format('YYYY-MM-DD'),
      end_date: this.queryDate.endOf('month').add(7, 'day').format('YYYY-MM-DD'),
    })

    if (!deliveries.getSalesHandingReportByDate) {
      return
    }
    this.deliveries = deliveries.getSalesHandingReportByDate.filter(delivery => delivery.deliveryDate)

    const config = process?.[0]?.config

    this.timeStart =
      dayjs(`${dayjs().format('YYYY-MM-DD')} ${config.timeStart}`)
        .subtract(1, 'hour')
        .format('HH:mm')
  }

  async mounted () {
    this.getData()
  }

  beforeUpdate () {
    if (this.$refs.calendar) {
      this.currentMonth = this.$refs.calendar.title
      this.currentMonth = this.currentMonth.charAt(0).toUpperCase() + this.currentMonth.slice(1)
    }
  }

  // Getters
  get currentType () {
    return this.type[this.category]
  }

  get currentDayEvents () {
    const targetDate = this.focus !== '' ? dayjs(this.focus) : dayjs()
    return this.events.filter(event => dayjs(event.start).isSame(targetDate, 'day'))
  }

  @Watch('deliveries')
  async onDataChanged () {
    this.events = []
    this.deliveries.forEach(delivery => {
      const newEvent: Record<string, any> = {
        name: `Entrega ${(delivery.closingReason ? delivery.closingReason?.description || delivery.closingReason?.type?.description : delivery.status?.description || delivery.status?.status?.description).toLowerCase()}`,
        start: dayjs(delivery.deliveryDate)
          .format('YYYY-MM-DD HH:mm'),
        end: dayjs(delivery.deliveryDate)
          .add(delivery.duration.hours || 1, 'hour')
          .format('YYYY-MM-DD HH:mm'),
        delivery,
        closingReason: delivery.closingReason,
        status: delivery.status,
        pipeline: delivery.pipeline,
        car: delivery.autoDescription + ' ' + delivery.year,
        plate: delivery.registrationPlate,
        color: delivery.closingReason ? (delivery.closingReason?.color || delivery.closingReason?.type?.color).split(' ')[0] : (delivery?.status?.color || delivery.status?.status?.color).split(' ')[0],
        icon: delivery.closingReason ? delivery.closingReason?.icon?.main || delivery.closingReason?.type?.icon?.main : delivery?.status?.icon?.main || delivery.status?.status?.icon?.main,
      }
      this.events.push(newEvent)
    })

    this.events.sort((firstEvent, secondEvent) => {
      if (dayjs(firstEvent.start) < dayjs(secondEvent.start)) return 1
      return -1
    })
  }

  @Watch('queryDate')
  updateData () {
    this.getData()
  }

  @Watch('category')
  updateCategory (newValue) {
    this.category = newValue
  }
  }
