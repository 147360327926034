import { render, staticRenderFns } from "./DeliveryScheduleView.vue?vue&type=template&id=9cf28f40&scoped=true&"
import script from "./DeliveryScheduleView.vue?vue&type=script&lang=ts&"
export * from "./DeliveryScheduleView.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryScheduleView.vue?vue&type=style&index=0&id=9cf28f40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf28f40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCalendar,VChip,VChipGroup,VCol,VContainer,VDialog,VIcon,VRow})
